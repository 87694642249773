import React, { useEffect, useState } from "react";

const ProgSkills = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    // Simulated data fetching or initialization
    const fetchedSkills = [
      { name: "HTML5", percent: 85 },
      { name: "CSS3 & SASS", percent: 80 },
      { name: "Bootstrap", percent: 60 },
      { name: "Tailwind CSS", percent: 60 },
      { name: "JavaScript", percent: 70 },
      { name: "jQuery", percent: 80 },
      { name: "React JS", percent: 70 },
      { name: "Next JS", percent: 60 },
      { name: "PHP", percent: 80 },
      { name: "Symfony", percent: 85 },
      { name: "Node.js & Express.js", percent: 20 },
      { name: "GraphQL", percent: 50 },
      { name: "API Platform", percent: 80 },
      { name: "REST API's", percent: 80 },
      { name: "SOAP", percent: 60 },
      { name: "MySQL", percent: 75 },
      { name: "PostgreSQL", percent: 75 },
      { name: "MongoDB", percent: 40 },
      { name: "Sanity", percent: 60 },
      { name: "Firebase", percent: 50 },
      { name: "Docker", percent: 60 },
      { name: "Kubernetes", percent: 40 },
      { name: "UML", percent: 70 },
      { name: "WordPress", percent: 85 },
      { name: "Moodle", percent: 60 },
      { name: "Testing", percent: 40 },
      { name: "Python", percent: 30 },
      { name: "Java", percent: 30 },
    ];

    // Set state with fetched or initialized data
    setSkills(fetchedSkills);
  }, []);

  return (
    <div className="skills-prog">
      <h3>
        <i className="fas fa-code"></i>Programming Skills
      </h3>
      <ul>
        {skills.map((skill, index) => (
          <li key={index} data-percent={skill.percent}>
            <span>{skill.name}</span>
            <div className="skills-bar">
              <div className="bar" style={{ width: `${skill.percent}%` }}></div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProgSkills;
