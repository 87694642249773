import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { SiCodepen } from "react-icons/si";
import { FaGithubSquare } from "react-icons/fa";
import ProfilePicture from "./../../Assets/img/profile-light.jpg";
const About = () => {
  return (
    <section className="section-about" id="section-about">
      <div className="profile">
        <img alt=" 11" className="image" src={ProfilePicture} />
        <div className="social-links">
          <a
            href="https://twitter.com/HabbaziJalal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSquareXTwitter />
          </a>
          <a
            href="https://www.linkedin.com/in/jalaleddine/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://codepen.io/jalal-eddine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiCodepen />
          </a>
          <a
            href="https://github.com/Jalal-Eddine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithubSquare />
          </a>
        </div>
      </div>
      <div className="about_text">
        <h3 className="heading-tertiary u-margin-bottom-small">Hello,</h3>
        <p className="paragraph">
          I'm Jalal-Eddine, a passionate full-stack web developer.
          <br />
        </p>

        <h3 className="heading-tertiary u-margin-bottom-small">
          {" "}
          Why Choose Me?
        </h3>
        <p className="paragraph">
          &rarr; Exceeding Expectations: I believe in going beyond what's
          expected, ensuring my clients receive more than they anticipate.
          <br />
          &rarr; Honesty & Integrity: Transparency is key. I treat every project
          as if it were my own, ensuring mutual success.
          <br />
          &rarr; Responsive Communication: I keep communication open and provide
          guidance on technology choices to meet your project's needs
          effectively. <br />
          &rarr; Empathy and Respect: Respect and empathy are core values in all
          my interactions, enhancing client experiences and outcomes.
          <br />
          🙋🏼‍♂️ I'm excited to collaborate with you, offering reliable, consistent,
          and high-level solutions to your development challenges. Let's discuss
          how we can fully address your business needs!
        </p>
        <h3 className="heading-tertiary u-margin-bottom-small">
          Technical Skills
        </h3>
        <p className="paragraph">
          These are my skills set, please contact me if you think I am fit for
          your needs.
          <br />
          <strong> ➡️ Front-End:</strong> HTML5, CSS3/SCSS, Bootstrap, Tailwind
          CSS, JavaScript, TypeScript, React JS, Next JS, jQuery.
          <br />
          <strong>➡️ Back-End:</strong> PHP, Symfony, REST API, API Platform.
          <br />
          <strong>➡️ Tools & DevOps:</strong> Docker, Kubernetes, GraphQL.
          <br />
          <strong>➡️ CI/CD:</strong> GitHub Actions, GitLab CI/CD
          <br />
          <strong>➡️ Databases & Cloud Services:</strong> MySQL, PostgreSQL,
          MongoDB, Firebase, Sanity.
          <br />
          <strong>➡️ CMS & LMS:</strong> CMS & LMS: WordPress, Shopify, Moodle.
          <br />
          <strong>➡️ Modeling:</strong> UML.
          <br />
          <strong>➡️ Environment & Tools:</strong> VS Code, Postman, Git, NPM,
          Yarn, Slack, Confluence, Windows, Linux (Ubuntu).
          <br />
          <strong>➡️ Graphic Design:</strong> Adobe Photoshop, Adobe XD, Canva,
          Figma.
          <br />
          <strong>➡️ Deployment:</strong>Namecheap, OVH, O2switch, Hostinger,
          Heroku, Netlify, Vercel.
          <br />
          <strong>➡️ Testing:</strong> PHPUnit, PHPStan, Jest, React Testing
          Library.
          <br />
          <strong>➡️ Application Development:</strong>
          Java (OOP), Python (basics).
          <br />
          <strong>➡️ Additional Skills:</strong> SEO, Facebook Ads, Google
          Analytics, Adobe Photoshop. I'm familiar with SEO, Facebook ads,
          google analytics, Adobe photoshop.
          <br />I also have experience in web design using Adobe XD and
          proficiency in video and audio editing with After Effects, Premiere
          Pro, and Audition.
        </p>

        <a href="/resume" className="btn-text">
          View Resume &rarr;
        </a>
      </div>
    </section>
  );
};

export default About;
